/* eslint-disable @typescript-eslint/no-unused-vars */
"use client"

import React, { useState, useEffect } from "react"
import { useRouter, usePathname } from "next/navigation"
import { useSession, signOut } from "next-auth/react"
import Image from "next/image"
import { Badge } from "@/components/ui/badge"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu"
import { Skeleton } from "@/components/ui/skeleton"
import Link from "next/link"
import LoginDialog from "@/components/navigation/LoginDialog"
import { sendVerifyCode } from "@/actions/sendVerifyCode"
import { useFetchSubscription } from "@/hooks/useFetchSubscription"

const UserNavigation: React.FC = () => {
  const { data: session, status } = useSession()
  const user = session?.user
  const router = useRouter()
  const pathname = usePathname()

  const isSubscribed = useFetchSubscription(user?.id)

  console.log(session)

  useEffect(() => {
    if (status === "loading") return

    if (session) {
      if (!session.user.isEmailVerified && pathname !== "/auth/verify") {
        router.push("/auth/verify")
      }
    }
  }, [session, status, router, pathname])

  // 確認コードの再送信
  useEffect(() => {
    const resendVerificationCode = async () => {
      if (
        session &&
        session.user.email &&
        !session.user.isEmailVerified &&
        pathname === "/auth/verify"
      ) {
        try {
          await sendVerifyCode({ email: session.user.email })
        } catch (error) {
          console.error("確認コードの送信に失敗しました:", error)
        }
      }
    }

    resendVerificationCode()
  }, [session, pathname])

  if (status === "loading") {
    return (
      <div className="ml-12">
        <Skeleton className="h-10 w-10 rounded-full" />
      </div>
    )
  }

  if (user) {
    // ログインしている場合
    const handleSignOut = async (event: Event) => {
      event.preventDefault()
      await signOut({ callbackUrl: "/" })
    }

    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="relative w-10 h-10 flex-shrink-0 ml-12">
              <Image
                src={user.image || "/default.png"}
                className="rounded-full object-cover"
                alt={user.name || "avatar"}
                fill
                priority
              />
            </div>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="p-2 w-[300px]" align="end">
            {/* <Link href={`/author/${user.id}`}> */}
            <DropdownMenuLabel className="bg-muted/70">
              <div className="break-words min-w-0">
                <div className="mb-2 text-primary font-semibold text-base">
                  {user.name || ""}
                </div>
                <div>
                  {isSubscribed ? (
                    <Badge variant="premium">Premium</Badge>
                  ) : (
                    <Badge variant="free">Free</Badge>
                  )}
                </div>
              </div>
            </DropdownMenuLabel>
            {/* </Link> */}

            <DropdownMenuSeparator />

            {/* <Link href="/post/new">
              <DropdownMenuItem className="cursor-pointer">
                新規投稿
              </DropdownMenuItem>
            </Link> */}

            {/* {isSubscribed === null ? (
              // サブスクリプション情報の取得中
              <DropdownMenuItem className="cursor-pointer">
                読み込み中...
              </DropdownMenuItem>
            ) : isSubscribed ? (
              // サブスクライブしている場合
              <DropdownMenuItem className="cursor-pointer">
                プレミアム会員
              </DropdownMenuItem>
            ) : (
              // サブスクライブしていない場合
              <Link href="/payment">
                <DropdownMenuItem className="cursor-pointer">
                  プレミアム会員登録
                </DropdownMenuItem>
              </Link>
            )} */}

            <Link href="/settings/profile">
              <DropdownMenuItem className="cursor-pointer">
                アカウント設定
              </DropdownMenuItem>
            </Link>

            <DropdownMenuItem
              onSelect={handleSignOut}
              className="text-red-600 cursor-pointer"
            >
              ログアウト
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </>
    )
  } else {
    // ログインしていない場合
    return <LoginDialog />
  }
}

export default UserNavigation
