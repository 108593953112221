// hooks/useFetchSubscription.ts
import { useState, useEffect, useCallback } from "react"

interface SubscriptionData {
  isSubscribed: boolean
}

export const useFetchSubscription = (userId: string | undefined) => {
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null)

  const fetchSubscription = useCallback(async () => {
    if (!userId) {
      setIsSubscribed(null)
      return
    }

    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_APP_URL}/api/subscription`,
      )
      if (response.ok) {
        const data: SubscriptionData = await response.json()
        setIsSubscribed(data.isSubscribed)
      } else {
        console.error("読み込みエラーが発生しました")
        setIsSubscribed(false)
      }
    } catch (error) {
      console.error("読み込みエラーが発生しました:", error)
      setIsSubscribed(false)
    }
  }, [userId])

  useEffect(() => {
    fetchSubscription()
  }, [fetchSubscription])

  return isSubscribed
}
