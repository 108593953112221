"use client"

import React, { useState, useCallback } from "react"
import Link from "next/link"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog"
import { FcGoogle } from "react-icons/fc"
import { signIn } from "next-auth/react"
import toast from "react-hot-toast"
import ThemeImage from "@/components/ThemeImage"

const LoginDialogComponent: React.FC = () => {
  const [open, setOpen] = useState(false)

  // ログインハンドラを useCallback でメモ化
  const handleGoogleLogin = useCallback(async () => {
    try {
      const result = await signIn("google", { callbackUrl: "/" })

      if (result?.error) {
        toast.error("ログインに失敗しました")
      } else {
        setOpen(false)
      }
    } catch (error) {
      toast.error("ログインに失敗しました")
    }
  }, [])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="premium"
          className="border-primary/80 hover:ring-2 hover:ring-primary/80 transition duration-500"
        >
          ログイン
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[450px]">
        <DialogHeader className="space-y-2">
          <DialogTitle>
            <div className="mx-auto w-full">
              <ThemeImage />
            </div>
          </DialogTitle>
          <DialogDescription className="text-left leading-relaxed">
            ストラボ | structurebox
            は、建築構造設計の知見やアイデアを情報共有するプラットフォームです。
          </DialogDescription>
        </DialogHeader>
        <Button
          variant="outline"
          className="mx-auto w-3/4 rounded-full mt-2 py-6 text-md font-bold"
          onClick={handleGoogleLogin}
        >
          <FcGoogle className="mr-2 h-5 w-5" />
          Googleでログイン
        </Button>

        <DialogFooter>
          <div className="grid gap-2 py-2">
            <div className="text-left text-sm leading-relaxed text-muted-foreground">
              ログインすることで、
              <Link href="/terms" className="text-blue-500 hover:underline">
                利用規約
              </Link>
              と
              <Link
                href="/privacy-policy"
                className="text-blue-500 hover:underline"
              >
                プライバシーポリシー
              </Link>
              に同意したことになります。
            </div>
            <Button
              variant="link"
              className="mx-auto w-3/4 font-bold"
              onClick={() =>
                window.open(
                  "https://www.google.com/intl/ja/account/about/",
                  "_blank",
                )
              }
            >
              Googleアカウントをお持ちでない方
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(LoginDialogComponent)
