import prisma from "@/lib/prisma"
import { generateVerificationCode } from "@/lib/generateCode"

interface SendVerifyCodeParams {
  email: string
}

export async function sendVerifyCode({ email }: SendVerifyCodeParams) {
  try {
    // 確認コードを生成
    const code = generateVerificationCode()
    const codeExpiresAt = new Date(Date.now() + 10 * 60 * 1000) // 10分後

    // ユーザーの確認コードと有効期限を更新
    await prisma.user.update({
      where: { email },
      data: {
        verificationCode: code,
        codeExpiresAt,
      },
    })

    // 確認コードを送信するAPIを呼び出す
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_APP_URL}/api/send/verify-code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code,
          sendTo: email,
        }),
      },
    )

    if (!response.ok) {
      const errorData = await response.json()
      console.error("確認コードの送信に失敗しました:", errorData)
      throw new Error("Failed to send verification code")
    }

    return true
  } catch (error) {
    console.error("確認コードの送信中にエラーが発生しました:", error)
    throw error
  }
}
