import { PrismaClient } from "@prisma/client"

// PrismaClientのインスタンス作成
const client = globalThis.prisma || new PrismaClient()

if (process.env.NODE_ENV !== "production") {
  globalThis.prisma = client // ここでグローバルにインスタンスを保存
}

export default client
